import React from "react";

import profile from "../../assets/Einstein.png";
import Container from "../../components/ui/Container";
import FlexRowReverse from "../../components/ui/FlexRowReverse";
import Button from "../../components/ui/Button";

const AboutSection = () => {
    // const {scrolling}  = useContext
    return (
        <>
            <section className="clr-white z-10 bg-rose-500">
                <div className="py-5 overflow-hidden flex-center-both flex-col">
                    <div className="container pt-20">
                        <div className="row flex-center-both">
                            <div className="">
                                <div className=" text-center">
                                    <div className="font-h2">
                                        منصة <span className="font-w-bold font-neu"> Fullmark</span>{" "}
                                        في اللغة الإنجليزية
                                    </div>
                                    {/* <div className="w-36 h-1 mx-auto bg-white mt-8 mb-3"></div> */}
                                    <FlexRowReverse className="font-h1 pt-3">
                                        <span className="font-w-bold gradient pb-2">
                                            لشرح اللغة الانجليزية
                                        </span>
                                        <span>للمرحلة الثانوية</span>
                                    </FlexRowReverse>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-36 h-1 mx-auto bg-white mt-8 mb-3 rounded-md opacity-50"></div>
                    <div className="mt-5">هل تحتاج المساعدة في مادة الأحياء ؟</div>
                    <div className="py-3 font-w-bold font-h2">
                        انضم الآن لمنصة الدكتورة لـ د/ميار هشام !
                    </div>
                    <Button
                        color="purple"
                        className="rounded-full font-w-bold font-h3 px-10 py-2"
                        element="a"
                        href="https://fullmark-bio.com/"
                    >
                        {" "}
                        انضم الآن !
                    </Button>
                </div>
            </section>
            {/* <section className="home__counter py-5">
                <Container className="py-5">
                    <div className="home-counter__container flex font-h1 flex-center-both flex-col  py-5">
                        <div className="home-counter__first">كسبنا ثقة اكثر من</div>
                        <div className="home-counter__middle font-w-bold font-bigmain px-4 text-rose-500">
                            <span className="thous">500</span>,
                            <span className="hunderds font-bigmax">000</span> +
                        </div>
                        <div className="home-counter__last">طالب</div>
                    </div>
                </Container>
            </section> */}
        </>
    );
};

export default AboutSection;
