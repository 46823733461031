import React from "react";
import { printFullDate } from "../../../utils/time";
import RemoteTable from "../../../components/ui/RemoteTable";
// import { getPlatformPlaceHolder } from "../../../services/contentServices";

const UserCustomLimitsTable = ({ user = { id: 0 } }) => {
    let columns = [
        {
            name: "التسلسل",
            reorder: true,
            selector: (row) => row.id,
            sortable: true,
            sortField: "id",
        },
        {
            name: "اسم المحتوى",
            reorder: true,
            selector: (row) =>
                row.sectionable ? (
                    row.sectionable.name
                ) : (
                    <span className="text-rose-500"> --- لم يتم العثور علي اسم المحتوى --- </span>
                ),
            sortable: true,
            sortField: "sectionable_id",
        },
        {
            name: "نوع الليميت الجديد",
            reorder: true,
            selector: (row) =>
                row.limit_name === "video_view_limit"
                    ? "عدد المشاهدات"
                    : row.limit_name === "exam_open_limit"
                    ? "عدد المشاهدات"
                    : "",
            sortable: true,
            sortField: "limit_name",
        },
        {
            name: "الليميت الجديد",
            reorder: true,
            selector: (row) => row.new_limit,
            sortable: true,
            sortField: "new_limit",
        },
        {
            name: "تاريخ آخر تعديل لليميت",
            reorder: true,
            selector: (row) => printFullDate(row.updated_at),
            sortable: true,
            sortField: "updated_at",
        },
    ];
    return (
        <div>
            <div className="w-full">
                <RemoteTable
                    api={`/api/users/${user["id"]}/profile/custom_user_limits/paginate`}
                    columns={columns}
                />
            </div>
        </div>
    );
};

export default UserCustomLimitsTable;
