import React, { useContext } from "react";

import "./MainSection.css";

import Container from "../../components/ui/Container";
import bgBottomLeft from "../../assets/bottom-left.png";
import bgBottomRight from "../../assets/bottom-right.png";
import bg from "../../assets/bg.png";
import Button from "../../components/ui/Button";
const MainSection = ({ title = false }) => {
    return (
        <>
            <section className="md:h-screen min-h-screen negative-nav-margin bg-indigo-700 relative overflow-hidden">
                <div
                    className="inset-0 w-full h-full z-0 absolute opacity-0"
                    style={{
                        backgroundImage: "url(" + bg + ")",
                        backgroundSize: "cover",
                        backgroundPosition: "center top",
                        // backgroundRepeat: "repeat-y",
                    }}
                ></div>
                <img
                    className="bottom-0 left-0 max-w-md md:max-w-3xl w-full z-0 absolute"
                    src={bgBottomLeft}
                    alt="bg-bottom-left"
                />
                <img
                    className="bottom-0 right-0 max-w-xl w-full z-0 hidden md:block md:absolute"
                    src={bgBottomRight}
                    alt="bg-bottom-left"
                />
                <Container className="h-full relative z-10 font-the-sans pt-32 md:pt-0 pb-20 md:pb-0">
                    <div className="w-full flex md:flex-row-reverse flex-col h-full space-y-20 md:space-y-0">
                        <div className="md:basis-1/2 basis-full h-full flex-center-both">
                            <div className="w-full h-full flex-center-both max-w-md">
                                {/* <img src={profile} alt={"profile"} className="" /> */}
                            </div>
                        </div>
                        <div className=" md:basis-1/2 basis-full h-full flex-center-both">
                            <div className="flex-col clr-white smooth space-y-6 md:space-y-5 text-center md:text-right">
                                {/* <h2 className="font-h1 font-w-bold">منصة</h2> */}
                                <h1 className="font-bigmax font-w-bold before-box relative">
                                    <span className="relative z-10">
                                        م / عبد الحميد <span className="">حامد</span>
                                    </span>
                                </h1>
                                <div className="w-40 h-1 bg-white mx-auto md:mx-0"></div>
                                <div className="flex-col">
                                    <h2 className="font-h2">
                                        مدرس{" "}
                                        <span className="text-yellow-500">اللغة الانجليزية</span>{" "}
                                        للثانوية العامة
                                    </h2>
                                </div>
                                {title ? <div className="font-h2">{title}</div> : ""}
                                <div>
                                    <Button className="font-h3 font-w-bold" color={"emerald"}>
                                        شراء الكتب
                                    </Button>
                                </div>
                                <div className="py-16"></div>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    );
};

export default MainSection;
