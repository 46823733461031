import React from "react";

import { ScrollingProvider } from "../../context/ScrollingContext";

import SectionHead from "../../components/ui/SectionHead";
import Container from "../../components/ui/Container";
import CourseCard from "../../components/ui/CourseCard";

// import year1 from "../../../assets/year-2.jpeg";
import year2 from "../../assets/year-2.png";
import year3 from "../../assets/year-3.png";
import year1 from "../../assets/year-1.png";
import Waves from "../../components/ui/Waves";

const YearsSection = ({ title = false }) => {
    // const { token } = useContext(AuthContext);
    return (
        <div className="relative mb-20 overflow-hidden" id="courses">
            <ScrollingProvider>
                <SectionHead title={title ? title : "السنوات الدراسية"} />
            </ScrollingProvider>

            <Container>
                <div
                    className={`g-teal-400 smooth clr-text-primary drk:bg-teal-800 bg-opacity-50 dark:bg-opacity-50 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10`}
                >
                    <CourseCard
                        name="الصف الدراسي الأول"
                        description="جميع كورسات الصف الأول الثانوي"
                        isYear={1}
                        isStaticPicture={year1}
                    />
                    <CourseCard
                        name="الصف الدراسي الثاني"
                        description="جميع كورسات الصف الثاني الثانوي"
                        isYear={2}
                        isStaticPicture={year2}
                    />
                    <CourseCard
                        name="الصف الدراسي الثالث"
                        description="جميع كورسات الصف الثالث الثانوي"
                        isYear={3}
                        isStaticPicture={year3}
                    />
                </div>
            </Container>

            {/* <Waves className="fill-yellow-200" /> */}
            {/* <div className="py-16 bg-yellow-200"></div> */}
        </div>
    );
};

export default YearsSection;
